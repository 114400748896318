import React, { Component, lazy } from "react";
import WIBEditable from "../Atoms/WIBEditable";
import Tags from "../Atoms/Tags";
import IThread, { Reply as IReply } from "../../types/Thread";
import Reply from "./Reply";
import create_thread from "../../requests/create_thread";
import update_thread from "../../requests/update_thread";
import get_replies from "../../requests/get_replies";
import UserContext from "../../UserContext";
import { Link } from "react-router-dom";
import FormField from "../Atoms/FormField";
import { Form, Formik, FormikHandlers, FormikHelpers } from "formik";
import Select, { OptionTypeBase } from "react-select";
import * as Yup from "yup";
import TextAreaAutosize from "react-textarea-autosize";
import TagsDisplay from "../Tags";
import IconButton from "../Atoms/IconButton";
import EditIcon from "../Atoms/EditIcon";
import insertTextAtCursor from "insert-text-at-cursor";
import FormSubmit from "../Atoms/FormSubmit";

interface State {
  tags: string[];
  hasError: boolean;
  error: string;
  replies?: IReply[];
  replying: boolean;
  expanded: boolean;
  editing: boolean;
}

interface Props {
  project_id: number;
  thread?: IThread;
  creating: boolean;
  handlePost?: VoidFunction;
  onCancelCreate?: VoidFunction;
  refreshThread: (thread_id: number) => void;
}

interface Values {
  title: string;
  text: string;
  tags: string[];
}

export default class Thread extends Component<Props, State> {
  state = {
    tags: [],
    title: this.props.thread?.title || "",
    content: this.props.thread?.text || "",
    hasError: false,
    error: "",
    replies: this.props.thread?.replies || new Array<IReply>(),
    replying: false,
    submittable: false,
    dirty: false,
    expanded: false,
    editing: false,
    thread: this.props.thread,
  };

  private textAreaRef: React.RefObject<HTMLTextAreaElement> = React.createRef();

  refreshReplies = async () => {
    const thread_id = this.props.thread?.id || -1;
    try {
      const replies = await get_replies({ thread_id });
      this.setState({ replies });
    } catch (err) {
      this.setState({ hasError: true, error: "failed to refresh reply" });
    }
  };

  componentDidUpdate() {
    this.textAreaRef.current?.focus();
  }

  handleUpdate = async (values: Values) => {
    try {
      console.log("values: ", values);
      await update_thread({
        thread_id: this.props.thread?.id || -1,
        text: values.text,
        title: values.title,
        tag_list: values.tags.join(","),
      });
      this.setState({ editing: false });
      this.props.refreshThread(this.props.thread?.id || -1);
      // const hasThread = this.props.thread !== null && this.props.thread !== undefined;
      // const thread: IThread = {
      //   id: hasThread ? this.props.thread!.id : -1,
      //   user_id: hasThread ? this.props.thread!.user_id : -1,
      //   title: values.title,
      //   tags: values.tags.join(","),
      //   text: values.text,
      //   firstname: hasThread ? this.props.thread!.firstname : "",
      //   lastname: hasThread ? this.props.thread!.lastname : "",
      //   username: hasThread ? this.props.thread!.username : "",
      //   is_admin: hasThread ? this.props.thread!.is_admin : false,
      //   reply_count: hasThread ? this.props.thread!.reply_count : 0,
      //   replies: hasThread ? this.props.thread!.replies : [],
      // };
      // this.setState({ thread });
    } catch (err) {
      this.setState({ hasError: true, error: "failed to update thread" });
    }
  };

  handlePost = async (values: Values) => {
    try {
      await create_thread({
        project_id: this.props.project_id,
        taglist_init: values.tags.join(","),
        text: values.text,
        title: values.title,
      });
      this.props.handlePost && this.props.handlePost();
    } catch (err) {
      this.setState({ hasError: true, error: "failed to create thread" });
    }
  };

  handleSubmit = async (values: Values, bag: FormikHelpers<Values>) => {
    console.log(values);
    if (this.props.creating) {
      this.handlePost(values);
      return;
    }
    this.handleUpdate(values);
    bag.resetForm({ values });
  };

  handlePostReply = async () => {
    try {
      const replies = await get_replies({
        thread_id: this.props.thread?.id || -1,
      });
      this.setState({ replies, replying: false });
    } catch (err) {
      this.setState({ hasError: true, error: "failed to refresh replies" });
    }
  };

  render() {
    const hasThread = !this.props.creating; //this.props.thread !== null && this.props.thread !== undefined
    const isAdmin = hasThread ? this.props.thread!.is_admin : false;
    const defaultTags =
      this.props.thread?.tags === null
        ? undefined
        : this.props.thread?.tags.split(",").map((tag, i) => {
            return { tag: tag, id: i * 5000000, weight: 0 };
          });
    const initialValues = {
      title: this.props.thread?.title || "",
      text: this.props.thread?.text || "",
      tags:
        this.props.thread !== undefined && this.props.thread?.tags !== null
          ? this.props.thread!.tags.split(",")
          : new Array<string>(),
    };
    const validationSchema = Yup.object().shape({
      title: Yup.string().required("your post must have a title"),
      text: Yup.string().required("please add some content to your post"),
    });
    const formName = "wib-thread";
    return (
      <UserContext.Consumer>
        {({ needsLogin }) => (
          <div
            className={`wib-thread ${
              this.props.thread?.is_admin ? "is-edit" : this.props.creating ? "is-create" : ""
            } ${this.state.expanded ? "wib-thread-expanded" : "wib-thread-collapsed"}`}
          >
            <IconButton
              onClick={() => this.setState((state) => ({ expanded: !state.expanded }))}
              name={`process-expand-chevron-${this.state.expanded ? "expanded" : "collapsed"}`}
            />
            {
              /*this.props.thread?.is_admin*/ this.state.editing || this.props.creating ? (
                <Formik
                  initialValues={initialValues}
                  enableReinitialize
                  validationSchema={validationSchema}
                  onSubmit={this.handleSubmit}
                >
                  {({ values, handleChange, setFieldValue, handleBlur, touched, errors, resetForm, dirty }) => (
                    <Form className={`wf wf-form wf-${formName}`} name={formName}>
                      <FormField
                        onBlur={handleBlur}
                        labelText="Thread Title"
                        formName={formName}
                        itemName="title"
                        value={values.title}
                        onChange={handleChange}
                      />
                      {errors.title && touched.title && <div className="wib-error">{errors.title}</div>}
                      <FormField
                        labelText="Category"
                        formName="newproject"
                        itemName="tags"
                        value={values.tags}
                        onChange={handleChange}
                        customInput={() => (
                          <Tags
                            maxTags={3}
                            name="tags"
                            nativeOnBlur={handleBlur}
                            value={values.tags}
                            onChange={(val) => {
                              console.log(val);
                              setFieldValue("tags", val);
                            }}
                          />
                        )}
                      />
                      {errors.tags && <div className="wib-error">required</div>}
                      <FormField
                        onBlur={handleBlur}
                        labelText="Thread Text"
                        formName={formName}
                        itemName="text"
                        value={values.text}
                        onChange={handleChange}
                        customInput={() => (
                          <TextAreaAutosize
                            onKeyDown={(e) => {
                              if (e.which === 9) {
                                if (this.textAreaRef.current !== null && this.textAreaRef.current !== undefined) {
                                  insertTextAtCursor(this.textAreaRef.current as any, "    ");
                                  e.preventDefault();
                                }
                              }
                            }}
                            ref={this.textAreaRef as any}
                            onBlur={handleBlur}
                            value={values.text}
                            onChange={handleChange}
                            name="text"
                          />
                        )}
                      />
                      {errors.text && touched.text && <div className="wib-error">{errors.text}</div>}
                      <div className="wib-actions-container">
                        <button type="submit" className="submit-thread">
                          submit
                        </button>
                        {/* {dirty && (
                        )} */}
                        {/* {(this.props.creating || dirty) && ( */}
                        {/* )} */}
                        <button
                          className="cancel-thread"
                          onClick={() => {
                            this.props.creating
                              ? this.props.onCancelCreate && this.props.onCancelCreate()
                              : this.setState({ editing: false });
                            resetForm();
                          }}
                        >
                          cancel
                          {/* {this.props.creating ? "cancel" : "reset"} */}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              ) : (
                <>
                  <h4 className="wib-thread-title">{this.props.thread?.title || ""}</h4>
                  <div className="thread-tags">
                    {this.props.thread?.tags !== null &&
                      this.props.thread?.tags !== undefined &&
                      this.props.thread?.tags.split(",").map((tag) => <div className="thread-tag">{tag}</div>)}
                  </div>
                  <WIBEditable defaultValue={this.props.thread?.text || ""} />
                </>
              )
            }
            {this.props.thread !== null && this.props.thread !== undefined && (
              <Link to={`/profile/${this.props.thread!.user_id}`} className="wib-thread-username">
                {`@${this.props.thread!.username}`}
              </Link>
            )}
            <div className={!this.state.expanded ? "wib-hidden" : "wib-thread-replies "}>
              {hasThread &&
                this.state.replies.map((reply) => (
                  <Reply
                    refreshReply={this.refreshReplies}
                    project_id={this.props.project_id}
                    thread_id={this.props.thread?.id || -1}
                    onPostReply={this.handlePostReply}
                    reply={reply}
                  />
                ))}
              <div className="wib-create-reply">
                {this.state.replying && !this.props.creating && (
                  <Reply
                    refreshReply={this.refreshReplies}
                    onCancelCreate={() => this.setState({ replying: false })}
                    project_id={this.props.project_id}
                    creating
                    onPostReply={this.handlePostReply}
                    thread_id={this.props.thread?.id || -1}
                  />
                )}
              </div>
            </div>
            {!this.state.replying && !needsLogin && (
              <button onClick={() => this.setState({ replying: true, expanded: true })} className="wib-thread-addreply">
                comment
              </button>
            )}
            {!this.props.creating && this.props.thread?.is_admin && (
              <div className="wib-actions-container">
                <EditIcon onClick={() => this.setState({ editing: !this.state.editing })} />
              </div>
            )}
            <div className="reply-count">
              {this.state.replies?.length} comment{this.state.replies?.length !== 1 ? "s" : ""}
            </div>
          </div>
          // <div className="wib-thread">
          //   {this.state.hasError && (
          //     <div className="wib-thread-error">{this.state.error}</div>
          //   )}
          //   <WIBEditable
          //     className="wib-thread-title"
          //     editable={isAdmin || this.state.creating}
          //     oneLine
          //     value={this.state.title}
          //     onChange={(title) => this.setState({ dirty: true })}
          //     onBlur={(title) =>
          //       this.setState({
          //         title,
          //         submittable:
          //           title !== "" &&
          //           this.state.content !== "" &&
          //           this.state.dirty,
          //       })
          //     }
          //   />
          //   <Tags
          //     defaultTags={defaultTags}
          //     maxTags={3}
          //     onChange={(tags) =>
          //       this.setState({
          //         tags,
          //         submittable:
          //           this.state.title !== "" && this.state.content !== "",
          //       })
          //     }
          //     value={this.state.tags}
          //     onBlur={(tags) =>
          //       this.setState({
          //         tags,
          //         submittable:
          //           this.state.title !== "" && this.state.content !== "",
          //       })
          //     }
          //   />
          //   <WIBEditable
          //     className="wib-thread-text"
          //     editable={isAdmin || this.state.creating}
          //     value={this.state.content}
          //     onChange={() => this.setState({ dirty: true })}
          //     onBlur={(content) =>
          //       this.setState({
          //         content,
          //         submittable:
          //           this.state.title !== "" &&
          //           content !== "" &&
          //           this.state.dirty,
          //       })
          //     }
          //   />
          //   {hasThread && (
          //     <Link
          //       to={`/profile/${this.props.thread!.user_id}`}
          //       className="wib-thread-username"
          //     >
          //       {`@${this.props.thread!.username}`}
          //     </Link>
          //   )}
          //   {(isAdmin || this.state.creating) && this.state.submittable && (
          //     <div className="wib-thread-actions">
          //       <button
          //         className="wib-thread-submit"
          //         onClick={() =>
          //           this.state.creating
          //             ? this.handlePost()
          //             : this.handleUpdate()
          //         }
          //       >
          //         {this.state.creating ? "post" : "update"}
          //       </button>
          //     </div>
          //   )}
          //   {this.state.dirty && (
          //     <button
          //       className="wib-thread-cancel"
          //       onClick={() =>
          //         this.setState({
          //           dirty: false,
          //           submittable: false,
          //           title: this.props.thread?.title || "",
          //           content: this.props.thread?.text || "",
          //           tags:
          //             defaultTags !== undefined
          //               ? defaultTags?.map((tag) => tag.tag)
          //               : new Array<string>(),
          //         })
          //       }
          //     >
          //       cancel
          //     </button>
          //   )}
          //   <div className="wib-thread-replies">
          //     {hasThread &&
          //       this.state.replies.map((reply) => (
          //         <Reply
          //           project_id={this.props.project_id}
          //           thread_id={this.props.thread?.id || -1}
          //           onPostReply={this.handlePostReply}
          //           reply={reply}
          //         />
          //       ))}
          //   </div>
          //   {!this.state.replying && verifyLoggedin && (
          //     <button
          //       onClick={() => this.setState({ replying: true })}
          //       className="wib-thread-addreply"
          //     >
          //       reply
          //     </button>
          //   )}
          //   <div className="wib-create-reply">
          //     {this.state.replying && !this.state.creating && (
          //       <Reply
          //         project_id={this.props.project_id}
          //         creating
          //         onPostReply={this.handlePostReply}
          //         thread_id={this.props.thread?.id || -1}
          //       />
          //     )}
          //     {this.state.replying && (
          //       <button
          //         className="wib-reply-cancel"
          //         onClick={() => this.setState({ replying: false })}
          //       >
          //         cancel
          //       </button>
          //     )}
          //   </div>
          // </div>
        )}
      </UserContext.Consumer>
    );
  }
}
