import React, { useState } from "react";
import IContainer from "../../../types/Container";
import WibMediaViewer from "../../Atoms/WIBMediaViewer";
import path from "path";
import { MODULE_CONTENT_URL } from "../../../requests/base_url";
import DeleteIcon from "../../Atoms/DeleteIcon";
import Axios from "axios";
import { containerCSS } from "react-select/src/components/containers";
import ReactPlayer from "react-player";
import Editor from "react-simple-code-editor";
import linkifyHtml from "linkifyjs/html";
import Latex from "../../Atoms/Latex";
import Markdown from "../../Atoms/Markdown";

import highlight from "highlight.js/lib/core";
import java from "highlight.js/lib/languages/java";
import c from "highlight.js/lib/languages/c";
import python from "highlight.js/lib/languages/python";
import cpp from "highlight.js/lib/languages/cpp";
import csharp from "highlight.js/lib/languages/csharp";
import javascript from "highlight.js/lib/languages/javascript";
import php from "highlight.js/lib/languages/php";
import swift from "highlight.js/lib/languages/swift";
import sql from "highlight.js/lib/languages/sql";
import ruby from "highlight.js/lib/languages/ruby";
import objectivec from "highlight.js/lib/languages/objectivec";
import go from "highlight.js/lib/languages/go";
import visualbasic from "highlight.js/lib/languages/vbnet";
import d from "highlight.js/lib/languages/d";
import r from "highlight.js/lib/languages/r";
import perl from "highlight.js/lib/languages/perl";
import matlab from "highlight.js/lib/languages/matlab";
import latex from "highlight.js/lib/languages/latex";
import markdown from "highlight.js/lib/languages/markdown";
highlight.registerLanguage("java", java);
highlight.registerLanguage("c", c);
highlight.registerLanguage("python", python);
highlight.registerLanguage("cpp", cpp);
highlight.registerLanguage("csharp", csharp);
highlight.registerLanguage("vbnet", visualbasic);
highlight.registerLanguage("javascript", javascript);
highlight.registerLanguage("php", php);
highlight.registerLanguage("swift", swift);
highlight.registerLanguage("sql", sql);
highlight.registerLanguage("ruby", ruby);
highlight.registerLanguage("objective-c", objectivec);
highlight.registerLanguage("go", go);
highlight.registerLanguage("d", d);
highlight.registerLanguage("r", r);
highlight.registerLanguage("perl", perl);
highlight.registerLanguage("matlab", matlab);
highlight.registerLanguage("latex", latex);
highlight.registerLanguage("markdown", markdown);

interface Props {
  container: IContainer;
  onDelete?: VoidFunction;
  onContentChange: (content: string) => void;
  key: string;
  editor: boolean;
}

interface State {
  loadedText: string;
  isText: boolean;
  isDirty: boolean;
  isCode: boolean;
  codeValue: string;
}

export default class Container extends React.Component<Props, State> {
  state = {
    loadedText: "",
    isText: path.extname(this.props.container.content_loc) === ".txt",
    isCode: path.extname(this.props.container.content_loc) === ".code",
    isLatex: path.extname(this.props.container.content_loc) === ".latex",
    isMarkdown: path.extname(this.props.container.content_loc) === ".md",
    codeValue: "",
    isDirty: false,
  };

  async componentDidMount() {
    if (this.state.isText || this.state.isCode || this.state.isLatex || this.state.isMarkdown) {
      const loadedText = (await Axios.get(`${MODULE_CONTENT_URL}/${this.props.container.content_loc}`)).data;
      this.setState({ loadedText, codeValue: loadedText });
    }
  }

  render() {
    return (
      <div className={`wib-container wib-container-content-${this.props.container.content_type}`}>
        {this.state.isMarkdown && (
          <div className="markdown">
            <Markdown
              key={this.state.loadedText}
              editor={this.props.editor}
              onContentChange={(value) => this.props.onContentChange(value)}
              defaultValue={this.state.loadedText}
            />
          </div>
        )}
        {this.state.isLatex && (
          <div className="latex">
            <Latex
              key={this.state.loadedText}
              editor={this.props.editor}
              onContentChange={(value) => this.props.onContentChange(value)}
              defaultValue={this.state.loadedText}
            />
          </div>
        )}
        {this.state.isCode && (
          <div className="codeinput">
            <Editor
              onBlur={() => {
                if (this.state.isDirty) {
                  this.setState({ isDirty: false });
                  this.props.onContentChange(this.state.codeValue);
                }
              }}
              onValueChange={(codeValue) => this.setState({ codeValue, isDirty: true })}
              value={this.state.codeValue}
              highlight={(code) => highlight.highlightAuto(code).value}
            >
              {this.state.loadedText}
            </Editor>
          </div>
        )}
        {this.state.isText ? (
          <pre>
            <div
              contentEditable
              onKeyUp={(e) => this.setState({ isDirty: true })}
              onBlur={(e) => {
                if (this.state.isDirty) {
                  this.setState({ isDirty: false });
                  this.props.onContentChange(e.target.innerText);
                }
              }}
              dangerouslySetInnerHTML={{
                __html: this.props.editor ? this.state.loadedText : linkifyHtml(this.state.loadedText),
              }}
              className={"textinput"}
            ></div>
          </pre>
        ) : this.props.container.content_type == 5 ? (
          <div className="video-player">
            <ReactPlayer url={this.props.container.content_loc} />
          </div>
        ) : (
          <WibMediaViewer
            docName={path.basename(this.props.container.content_loc)}
            docPath={`${MODULE_CONTENT_URL}/${this.props.container.content_loc}`}
          />
        )}
        <DeleteIcon onClick={this.props.onDelete} />
      </div>
    );
  }
}
