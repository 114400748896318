import React, { lazy } from "react";
import AddButtom from "../../Atoms/AddButton";
import connector, { IProps } from "../../../common_connector";
import RoleFocus from "./RoleFocus";
import uniqid from "uniqid";
import _ from "lodash";
import AddButton from "../../Atoms/AddButton";
import CollectionItemTitle from "../../Atoms/CollectionItemTitle";
import UserContext from "../../../UserContext";
import Role, { initial_role } from "../../../types/Role";
import WibConfirmModal from "../../Atoms/WibConfirmModal";
import unassign_user from "../../../requests/unassign_user";
import { flashErrorMessage, flashSuccessMessage } from "redux-flash";
import { fetch_project } from "../../../ducks/project";
import delete_role from "../../../requests/delete_role";
import delete_focus from "../../../requests/delete_focus";
import WibModal from "../../Atoms/WibModal";
import { ifError } from "assert";
import LazyWrapper from "../../LazyWrapper";
const AddCollabRole = lazy(() => import("../../Forms/AddCollabRole"));
const RoleForm = lazy(() => import("../../Forms/Role"));
const FocusForm = lazy(() => import("../../Forms/Focus"));
const RoleFocusForm = lazy(() => import("../../Forms/RoleFocus"));
const AssignmentForm = lazy(() => import("../../Forms/AssignFocus"));
const EditFocus = lazy(() => import("../../Forms/EditFocus"));

interface Props extends IProps {
  canEdit: () => boolean;
}

interface State {
  editingRole: boolean;
  selected_role: Role;
  creatingRole: boolean;
  assigning: boolean;
  selected_focus: number;
  confirming: boolean;
  onDeleteConfirm: VoidFunction;
  itemToDelete: number[];
  editingFocus: boolean;
  deletingLastRole: boolean;
}

class Roles extends React.Component<Props, State> {
  state = {
    editingRole: false,
    selected_role: initial_role,
    creatingRole: false,
    assigning: false,
    selected_focus: -1,
    editingFocus: false,
    confirming: false,
    onDeleteConfirm: () => {},
    itemToDelete: [-1],
    deletingLastRole: false,
  };

  deleteFocus = async () => {
    const { project } = this.props.appState;
    try {
      await delete_focus({
        project_id: project.project_id,
        focus_id: this.state.itemToDelete[0],
      });
      this.setState({ confirming: false });
      this.props.dispatch(flashSuccessMessage("Successfully deleted focus"));
      this.props.dispatch(fetch_project(project.project_id));
    } catch (err) {
      this.props.dispatch(flashErrorMessage("An error occured while deleting focus"));
    }
  };

  deleteRole = async () => {
    const { project } = this.props.appState;
    try {
      await delete_role({
        project_id: project.project_id,
        role_id: this.state.itemToDelete[0],
      });
      this.setState({ confirming: false });
      this.props.dispatch(flashSuccessMessage("Successfully deleted role"));
      this.props.dispatch(fetch_project(project.project_id));
    } catch (err) {
      this.props.dispatch(flashErrorMessage("An error occured while trying to delete role"));
    }
  };

  unassignUser = async () => {
    const { project } = this.props.appState;
    const { itemToDelete } = this.state;
    try {
      const role = _.find(project.roles, { role_id: itemToDelete[0] })!;
      const focus = _.find(role.foci, { focus_id: itemToDelete[1] })!;
      console.log(JSON.stringify(focus));
      if (focus.users.length === 1) {
        await delete_focus({
          project_id: project.project_id,
          focus_id: focus.focus_id,
        });
      } else {
        await unassign_user({
          focus_id: itemToDelete[1],
          user_id: itemToDelete[2],
          project_id: project.project_id,
        });
      }
      this.setState({ confirming: false });
      this.props.dispatch(flashSuccessMessage("Successfully unassigned user"));
      this.props.dispatch(fetch_project(project.project_id));
    } catch (err) {
      this.props.dispatch(flashErrorMessage("an error occured while unassigning the user"));
    }
  };

  render() {
    const { roles } = this.props.appState.project;
    const { project } = this.props.appState;
    let hasPending = false;
    roles.forEach((role) => {
      if (role.not_pending === 0) {
        hasPending = true;
      }
      role.foci.forEach((focus) => {
        focus.users.forEach((user) => {
          if (user.focus_accepted === 0) {
            hasPending = true;
          }
        });
      });
    });
    return (
      <UserContext.Consumer>
        {({ verifyLoggedin }) => (
          <>
            <WibConfirmModal
              details={
                this.state.deletingLastRole
                  ? "Warning: if you remove yourself completely from the project, you will no longer be able to edit - so please be sure someone else has at least one role / responsibility"
                  : undefined
              }
              onConfirm={() => this.state.onDeleteConfirm()}
              isOpen={this.state.confirming}
              onCancel={() => this.setState({ confirming: false })}
            />
            <WibModal
              additionalClass="create-role"
              title={"Create Role"}
              onDismiss={() => this.setState({ creatingRole: false })}
              isOpen={this.state.creatingRole}
            >
              <LazyWrapper>
                <AddCollabRole isRole onSuccess={() => this.setState({ creatingRole: false })} />
              </LazyWrapper>
              {/* <RoleForm
                creating={this.state.creatingRole}
                onSuccess={() =>
                  this.setState({ editingRole: false, creatingRole: false })
                }
                roleID={this.state.selected_role.role_id}
              /> */}
            </WibModal>
            <WibModal
              additionalClass="edit-role"
              title="Edit Role"
              onDismiss={() => this.setState({ editingRole: false })}
              isOpen={this.state.editingRole}
            >
              <LazyWrapper>
                <RoleForm
                  creating={false}
                  onSuccess={() => this.setState({ editingRole: false })}
                  roleID={this.state.selected_role.role_id}
                />
              </LazyWrapper>
            </WibModal>
            <WibModal
              additionalClass="edit-details"
              title="Edit Role Details"
              onDismiss={() => this.setState({ editingFocus: false })}
              isOpen={this.state.editingFocus}
            >
              <LazyWrapper>
                <EditFocus
                  onSuccess={() => this.setState({ editingFocus: false })}
                  selectedFocus={this.state.selected_focus}
                  selectedRole={this.state.selected_role.role_id}
                />
              </LazyWrapper>
            </WibModal>
            {/* <Panel
              isLightDismiss
              onDismiss={() =>
                this.setState({ editingFocus: false, creatingFocus: false })
              }
              type={PanelType.medium}
              isOpen={this.state.creatingFocus || this.state.editingFocus}
            >
              <FocusForm
                editing={this.state.editingFocus}
                onSuccess={() =>
                  this.setState({ editingFocus: false, creatingFocus: false })
                }
                roleID={this.state.selected_role}
                focusID={this.state.selected_focus}
              />
            </Panel> */}
            <WibModal
              onDismiss={() => this.setState({ assigning: false })}
              isOpen={this.state.assigning}
              additionalClass="assign-role-details"
            >
              <LazyWrapper>
                <AssignmentForm role={this.state.selected_role} onSuccess={() => this.setState({ assigning: false })} />
              </LazyWrapper>
            </WibModal>

            <div className="roles">
              <CollectionItemTitle name="roles" text="Roles, Details & Contributors" />
              {_.map(roles, (role) => (
                <RoleFocus
                  isEditor={this.props.appState.project.isAdmin}
                  // addFocus={() => {
                  //   if (this.props.canEdit()) {
                  //     this.setState({
                  //       creatingFocus: true,
                  //       selected_role: role.role_id,
                  //     });
                  //   }
                  // }}
                  // editFocus={(focusID) => {
                  //   if (this.props.canEdit()) {
                  //     this.setState({
                  //       editingFocus: true,
                  //       selected_role: role.role_id,
                  //       selected_focus: focusID,
                  //     });
                  //   }
                  // }}

                  onUnassignUser={(focus_id: number, user_id: number) =>
                    this.setState({
                      deletingLastRole:
                        project.roles.length === 1 &&
                        project.roles[0].foci.length === 1 &&
                        project.roles[0].foci[0].users.length === 1,
                      confirming: true,
                      onDeleteConfirm: this.unassignUser,
                      itemToDelete: [role.role_id, focus_id, user_id],
                    })
                  }
                  onDeleteRole={(role_id: number) => {
                    this.setState({
                      deletingLastRole: project.roles.length === 1,
                      confirming: true,
                      onDeleteConfirm: this.deleteRole,
                      itemToDelete: [role_id],
                    });
                  }}
                  onDeleteFocus={(focus_id: number) =>
                    project.isAdmin &&
                    this.setState({
                      deletingLastRole: project.roles.length === 1 && project.roles[0].foci.length === 1,
                      confirming: true,
                      onDeleteConfirm: this.deleteFocus,
                      itemToDelete: [focus_id],
                    })
                  }
                  addAssignment={() => this.setState({ selected_role: role, assigning: true })}
                  onEditFocus={(role_id: number, focus_id: number) => {
                    project.isAdmin &&
                      this.setState({
                        editingFocus: true,
                        selected_focus: focus_id,
                        selected_role: role,
                      });
                  }}
                  role={role}
                  key={uniqid()}
                  editRole={() => {
                    project.isAdmin &&
                      this.setState({
                        editingRole: true,
                        selected_role: role,
                      });
                  }}
                />
              ))}
              {hasPending && <div className="pending-details"></div>}
              <AddButton
                text="Add Role"
                onClick={() => {
                  if (this.props.canEdit()) {
                    this.setState({ creatingRole: true });
                  }
                }}
              />
            </div>
          </>
        )}
      </UserContext.Consumer>
    );
  }
}

export default connector(Roles);
