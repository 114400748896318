import React from "react";
import { connect } from "react-redux";
import AppState from "../../types/AppState";
import _ from "lodash";
import Text from "./Text";

const MessageBars = (props: { appState: AppState }) => (
  <div className="wib-user-message-container">
    {_.map(props.appState.flash.messages, (flash: { id: number; message: string; isError: boolean; props: any }) => (
      <div className="wib-message-bar-container">
        <div className={`wib-user-message wib-user-message-${flash.isError ? "error" : "success"}`}>
          <Text>{flash.message}</Text>
        </div>
      </div>
    ))}
  </div>
);

const mapStateToProps = (appState: AppState) => ({ appState });
export default connect(mapStateToProps)(MessageBars);
