import React, { Component, lazy } from "react";
import WibModal from "./Atoms/WibModal";
import querystring from "querystring";
import { Redirect } from "react-router";
import Login from "./Login";
import Search from "./Search";
import LazyWrapper from "./LazyWrapper";
const NewProject = lazy(() => import("./Forms/NewProject"));
const PasswordReset = lazy(() => import("./Forms/PasswordReset"));
const ForgotPassword = lazy(() => import("./Forms/ForgotPassword"));
const AcceptInvite = lazy(() => import("./Forms/AcceptInvite"));

interface State {
  redirectToHome: boolean;
}

export default class Home extends Component<any, State> {
  state = { redirectToHome: false };

  redirectToHome = () => this.setState({ redirectToHome: true });

  render() {
    const { search } = window.location;
    const searchParams = querystring.parse(search.replace("?", ""));

    if (this.state.redirectToHome) return <Redirect to="/" />;
    return (
      <div>
        <WibModal
          additionalClass="login-modal"
          isOpen={searchParams.login !== undefined}
          onDismiss={this.redirectToHome}
          title="Login"
        >
          <LazyWrapper>
            <Login onSuccess={this.redirectToHome} />
          </LazyWrapper>
        </WibModal>
        <WibModal
          additionalClass="new-project"
          isOpen={searchParams.signup !== undefined}
          onDismiss={this.redirectToHome}
          title="Signup"
        >
          <LazyWrapper>
            <NewProject onSuccess={this.redirectToHome} />
          </LazyWrapper>
        </WibModal>
        <WibModal
          additionalClass="forgot-password"
          isOpen={searchParams.forgotpassword !== undefined}
          onDismiss={this.redirectToHome}
          title="Forgot Password"
        >
          <LazyWrapper>
            <ForgotPassword onSuccess={this.redirectToHome} />
          </LazyWrapper>
        </WibModal>
        <WibModal
          isOpen={searchParams.passwordreset !== undefined}
          onDismiss={this.redirectToHome}
          title="Forgot Password"
        >
          <LazyWrapper>
            <PasswordReset onSuccess={this.redirectToHome} />
          </LazyWrapper>
        </WibModal>
        <WibModal
          additionalClass="accept-invitation"
          isOpen={searchParams.accept_invite !== undefined}
          onDismiss={this.redirectToHome}
          title="Accept Invitation"
        >
          <LazyWrapper>
            <AcceptInvite onSuccess={this.redirectToHome} />
          </LazyWrapper>
        </WibModal>
        <Search location={this.props.location} />
      </div>
    );
  }
}
