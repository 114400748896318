import React, { Component, useRef } from "react";
import WibModal, { Props as ModalProps } from "./WibModal";
import uniqid from "uniqid";

export interface Props {
  docPath: string;
}

export default (props: Props) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  // document.addEventListener("keydown", (e) => {
  //   if (e.which === 37 || e.which === 39) {
  //     const iframeWindow = iframeRef.current?.contentWindow as any;
  //     if (iframeWindow.keyPress) {
  //       console.log(e.which);
  //       iframeWindow.keyPress(e.which);
  //     }
  //   }
  // });

  return (
    <div className="pdf-iframe-wrapper">
      <iframe
        onClick={() => {
          console.log(iframeRef.current?.isSameNode(document.activeElement));
          iframeRef.current?.focus();
        }}
        title={uniqid()}
        ref={iframeRef}
        src={`https://origin.whatimbuilding.com/viewer/index.html?file=${props.docPath}`}
      />
    </div>
  );
};

// interface State {
//   currentPage: number;
//   numPages: number;
// }

// export default class PDFView extends Component<Props, State> {
//   state = { currentPage: 1, numPages: 0 };

//   render() {
//     return (
//       <div>

//       <div className="wib-pdf-view">
//         <Document
//           file={this.props.docPath}
//           onLoadSuccess={({ numPages }) => this.setState({ numPages })}
//         >
//           <Page pageNumber={this.state.currentPage} />
//         </Document>
//         <div className="wib-action-container">
//           {this.state.currentPage > 1 && (
//             <button
//               className="wib-button wib-button-previouspage"
//               onClick={(e) => {
//                 e.preventDefault();
//                 this.setState({
//                   currentPage: this.state.currentPage - 1,
//                 });
//               }}
//             >
//               previous
//             </button>
//           )}
//           {this.state.currentPage < this.state.numPages && (
//             <button
//               className="wib-button wib-button-nextpage"
//               onClick={(e) => {
//                 e.preventDefault();
//                 this.setState({
//                   currentPage: this.state.currentPage + 1,
//                 });
//               }}
//             >
//               next
//             </button>
//           )}
//         </div>
//       </div>
//     );
//   }
// }
