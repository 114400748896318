import React, { useState, lazy, Suspense } from "react";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter, Switch, Route, Link, Redirect } from "react-router-dom";
import Project from "./components/Project";
import Nav from "./components/Nav";
import _ from "lodash";
import MessageBars from "./components/Atoms/MessageBars";
import Footer from "./components/Atoms/Footer";
import Signup from "./components/Signup";
import "./app.css";
import Profile from "./components/Profile";
import Users from "./components/Users";
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./components/Login";
import UserContext from "./UserContext";
import checkNeedsLogin from "./needsLogin";
import Search from "./components/Search";
import WibModal from "./components/Atoms/WibModal";
import Home from "./components/Home";
import needsLogin from "./needsLogin";
import Feedback from "./components/Feedback";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import hotkeys from "hotkeys-js";
import Hotkeys from "react-hot-keys";
import LazyWrapper from "./components/LazyWrapper";
const NewProject = lazy(() => import("./components/Forms/NewProject"));
const AcceptInvite = lazy(() => import("./components/Forms/AcceptInvite"));

interface State {
  editor: boolean;
  isCreate: boolean;
  needsLogin: boolean;
  showFeedback: boolean;
}

export default class App extends React.Component<any, State> {
  state = {
    editor: true,
    isCreate: false,
    needsLogin: false,
    showFeedback: false,
  };

  componentDidMount() {
    this.sNeedsLogin(needsLogin());
  }

  sNeedsLogin(needsLogin: boolean) {
    this.setState({ needsLogin });
  }

  setIsCreate = (isCreate: boolean) => {
    this.setState({ isCreate });
  };

  setEditor = (editor: boolean) => {
    this.setState({ editor });
  };

  toggleFeedback = () => {
    this.setState({ showFeedback: !this.state.showFeedback });
  };

  render() {
    const { editor, isCreate, needsLogin } = this.state;
    return (
      <Provider store={store}>
        <BrowserRouter basename="/app">
          <UserContext.Provider
            value={{
              needsLogin: needsLogin,
              verifyLoggedin: () => this.sNeedsLogin(checkNeedsLogin()),
              setNeedsLogin: (loggedIn: boolean) => this.sNeedsLogin(loggedIn),
            }}
          >
            <Hotkeys onKeyDown={this.toggleFeedback} keyName="alt+k">
              {this.state.showFeedback && (
                <DndProvider backend={HTML5Backend}>
                  <Feedback onHide={() => this.setState({ showFeedback: false })} />
                </DndProvider>
              )}
              {/* {needsLogin && <Redirect to="/?login" />} */}
              {console.log("render", needsLogin)}
              <div
                className={`display-container display-container-${editor ? "editor" : "visitor"} display-container-${
                  !needsLogin ? "authed" : "unauthed"
                }`}
              >
                <Nav onCreate={() => this.setIsCreate(true)} onEditToggle={() => this.setEditor(!editor)} />
                <WibModal title="New Project" isOpen={isCreate} onDismiss={() => this.setIsCreate(false)}>
                  <LazyWrapper>
                    <NewProject onSuccess={() => this.setIsCreate(false)} />
                  </LazyWrapper>
                </WibModal>
                <MessageBars />
                <div className="wib-feedback-toggle">
                  <button
                    className="feeback-toggle-link"
                    onClick={(e) => {
                      this.setState({ showFeedback: !this.state.showFeedback });
                    }}
                  >
                    toggle feedback
                  </button>
                </div>
                <div className="wib-user-message-container">
                  <div className="wib-user-message"></div>
                </div>
                <Switch>
                  <Route
                    path="/projects/:project_id"
                    render={({ match, location }) => (
                      <Project key={location.key} match={match} setEditor={this.setEditor} />
                    )}
                  />
                  <Route
                    path="/projects"
                    render={() => _.map([1, 2, 3], (num) => <Link to={`/projects/${num}`}>Project {num}</Link>)}
                  />
                  <Route
                    path="/accept_invite"
                    render={({ location }) => (
                      <LazyWrapper>
                        <AcceptInvite key={location.key} />
                      </LazyWrapper>
                    )}
                  />
                  <Route path="/profile/:user_id" render={({ match }) => <Profile match={match} />} />
                  <Route path="/" render={({ location }) => <Home key={location.key} location={location} />} />
                  <Route path="/users" component={Users} />
                </Switch>
                <Footer />
              </div>
            </Hotkeys>
          </UserContext.Provider>
        </BrowserRouter>
      </Provider>
    );
  }
}
