import React, { lazy } from "react";
import connector, { IProps } from "../../../common_connector";
import _ from "lodash";
import uniqid from "uniqid";
import Integration from "./Integration";
import AddButton from "../../Atoms/AddButton";
import CollectionItemTitle from "../../Atoms/CollectionItemTitle";
import UserContext from "../../../UserContext";
import WibConfirmModal from "../../Atoms/WibConfirmModal";
import { flashSuccessMessage, flashErrorMessage } from "redux-flash";
import delete_integration from "../../../requests/delete_integration";
import { fetch_project } from "../../../ducks/project";
import WibModal from "../../Atoms/WibModal";
import LazyWrapper from "../../LazyWrapper";
const EditIntegration = lazy(() => import("../../Forms/EditIntegration"));
const AddIntegration = lazy(() => import("../../Forms/AddIntegration"));

interface Props extends IProps {
  canEdit: () => boolean;
}

interface State {
  editing: boolean;
  selected_integration: number;
  creating: boolean;
  confirming: boolean;
  toDelete: number;
}

class Integrations extends React.Component<Props, State> {
  state = {
    editing: false,
    selected_integration: -1,
    creating: false,
    confirming: false,
    toDelete: -1,
  };

  deleteIntegration = async () => {
    const { project } = this.props.appState;
    try {
      await delete_integration({
        project_id: project.project_id,
        integration_id: this.state.toDelete,
      });
      this.setState({ confirming: false });
      this.props.dispatch(flashSuccessMessage("Successfully deleted integration"));
      this.props.dispatch(fetch_project(project.project_id));
    } catch (err) {
      this.props.dispatch(flashErrorMessage("An error occured while deleting the integration"));
    }
  };

  render() {
    const { integrations } = this.props.appState.project;
    return (
      <>
        <WibConfirmModal
          onCancel={() => this.setState({ confirming: false })}
          onConfirm={this.deleteIntegration}
          isOpen={this.state.confirming}
        />
        <WibModal
          additionalClass="create-integration"
          title="Add Integration"
          isOpen={this.state.creating}
          onDismiss={() => this.setState({ creating: false })}
        >
          <LazyWrapper>
            <AddIntegration onSuccess={() => this.setState({ creating: false })} />
          </LazyWrapper>
        </WibModal>
        <WibModal
          additionalClass="edit-integration"
          title="Edit Integration"
          isOpen={this.state.editing}
          onDismiss={() => this.setState({ editing: false })}
        >
          <LazyWrapper>
            <EditIntegration
              selectedIntegration={this.state.selected_integration}
              onSuccess={() => this.setState({ editing: false })}
            />
          </LazyWrapper>
        </WibModal>
        <div className="integrations">
          <CollectionItemTitle name="collaborators" text="Team Resources: Tools, Links, & 3rd Party Integrations" />
          {_.map(integrations, (integration) => (
            <Integration
              onDelete={(id) => this.setState({ confirming: true, toDelete: id })}
              key={uniqid()}
              integration={integration}
              onClick={() => {
                if (this.props.canEdit()) {
                  this.setState({
                    editing: true,
                    selected_integration: integration.id,
                  });
                }
              }}
            />
          ))}
          <AddButton
            onClick={() => {
              if (this.props.canEdit()) {
                this.setState({ creating: true });
              }
            }}
            text="Add Integration"
          />
        </div>
      </>
    );
  }
}

export default connector(Integrations);
