import React, { Component, useRef } from "react";
import { useDrag, useDrop, DropTargetMonitor, XYCoord } from "react-dnd";
import TextArea from "react-textarea-autosize";
import html2canvas from "html2canvas";
import post_feedback from "../../requests/post_feedback";
import uniqId from "uniqid";

interface DroppableFeedbackProps {
  onDrop: (p: XYCoord) => void;
}
const DroppableFeedbackBox = (props: DroppableFeedbackProps) => {
  const [, drop] = useDrop({
    accept: "FEEDBACK_BOX",

    drop(item: any, monitor: DropTargetMonitor) {
      const delta = monitor.getDifferenceFromInitialOffset() as XYCoord;
      const x = Math.round(item.point.x + delta.x);
      const y = Math.round(item.point.y + delta.y);
      props.onDrop({ x, y });
      return undefined;
    },
  });
  return (
    <div
      ref={drop}
      style={{ width: "100%", height: "100%", background: "transparent", position: "absolute", zIndex: 9 }}
    />
  );
};

interface TextBoxProps {
  point: XYCoord;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSubmit: VoidFunction;
  value: string;
  error: string | undefined;
  onCancel: VoidFunction;
}

const DraggableTextBox = (props: TextBoxProps) => {
  const [{ isDragging }, drag] = useDrag({
    item: { type: "FEEDBACK_BOX", point: props.point },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={drag}
      style={{
        position: "absolute",
        left: props.point.x,
        top: props.point.y,
        zIndex: 10,
        display: isDragging ? "none" : "block",
      }}
    >
      <div className="draggable-feedback-container">
        <TextArea
          placeholder="Please send us any feedback - thank you!"
          style={{
            position: "sticky",
            backgroundColor: "var(--wib-dark-blue)",
            color: "white",
            fontSize: "1.5em",
            fontFamily: "var(--wib-text-family)",
            padding: "0.4em",
            display: "block",
            border: "medium none",
          }}
          value={props.value}
          onChange={props.onChange}
        />
        <button className="wff-button" onClick={() => props.onSubmit()}>
          Submit Feedback
        </button>
        <button className="wff-button wff-button-cancel" onClick={() => props.onCancel()}>
          Cancel
        </button>
        {props.error && <div className="wib-error">{props.error}</div>}
      </div>
    </div>
  );
};

interface FeedbackState {
  currentPosition: XYCoord;
  imgURL?: string;
  feedbackText: string;
  error?: string;
}

interface FeedbackProps {
  onHide: VoidFunction;
}

export default class Feedback extends Component<FeedbackProps, FeedbackState> {
  state = { currentPosition: { x: 100, y: 100 }, imgURL: undefined, feedbackText: "", error: undefined };

  handleSubmit = async (blob: Blob) => {
    try {
      const formData = new FormData();
      const file = new File([blob], `${uniqId()}.jpg`);
      formData.append("file", file);
      formData.append("feedback", this.state.feedbackText);
      await post_feedback(formData);
      this.props.onHide();
    } catch (err) {
      this.setState({ error: "could not post feedback" });
    }
  };

  render() {
    return (
      <>
        {this.state.imgURL && <img src={this.state.imgURL} />}
        <DroppableFeedbackBox onDrop={(currentPosition) => this.setState({ currentPosition })} />
        <div className="draggable-text-box-container">
          <DraggableTextBox
            onCancel={() => this.props.onHide()}
            error={this.state.error}
            value={this.state.feedbackText}
            point={this.state.currentPosition}
            onChange={(e) => this.setState({ feedbackText: e.target.value })}
            onSubmit={async () => {
              const canvas = await html2canvas(document.body);
              canvas.toBlob((blob) => {
                const imgURL = URL.createObjectURL(blob);
                // this.setState({ imgURL });
                this.handleSubmit(blob as any);
              });
            }}
          />
        </div>
      </>
    );
  }
}
