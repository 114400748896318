import React, { Component } from "react";
import { Reply as IReply } from "../../types/Thread";
import TextBox from "../Atoms/WIBEditable";
import WIBEditable from "../Atoms/WIBEditable";
import update_reply from "../../requests/update_reply";
import create_reply from "../../requests/create_reply";
import { Link } from "react-router-dom";
import FormField from "../Atoms/FormField";
import { Form, Formik, FormikHandlers, FormikProps, FormikHelpers, FormikBag } from "formik";
import * as Yup from "yup";
import TextareaAutosize from "react-textarea-autosize";
import EditIcon from "../Atoms/EditIcon";
import insertTextAtCursor from "insert-text-at-cursor";

interface Props {
  reply?: IReply;
  creating?: boolean;
  thread_id: number;
  onPostReply: VoidFunction;
  project_id: number;
  onCancelCreate?: VoidFunction;
  refreshReply: (reply_id: number) => void;
}

interface State {
  hasError: boolean;
  error: string;
  editing: boolean;
}

interface Values {
  text: string;
}

export default class Reply extends Component<Props, State> {
  state = {
    hasError: false,
    error: "",
    editing: false,
  };

  private textAreaRef: React.RefObject<HTMLTextAreaElement> = React.createRef();

  handleUpdate = async (values: Values) => {
    try {
      await update_reply({
        reply_id: this.props.reply?.id || -1,
        text: values.text,
      });
      this.setState({ editing: false });
      this.props.refreshReply(this.props.reply?.id || -1);
    } catch (err) {
      this.setState({ hasError: true, error: "failed to edit reply" });
    }
  };

  componentDidUpdate() {
    this.textAreaRef.current?.focus();
  }

  handlePost = async (values: Values) => {
    try {
      await create_reply({
        thread_id: this.props.thread_id,
        text: values.text,
        project_id: this.props.project_id,
      });
      this.props.onPostReply();
    } catch (err) {
      this.setState({ hasError: true, error: "failed to post reply" });
    }
  };

  handleSubmit = async (values: Values, bag: FormikHelpers<Values>) => {
    if (this.props.creating) {
      await this.handlePost(values);
      return;
    }
    await this.handleUpdate(values);
    bag.resetForm({ values });
  };

  render() {
    const initialValues = {
      text: this.props.reply?.text || "",
    };

    const validationSchema = Yup.object().shape({
      text: Yup.string().required("please input a reply"),
    });

    const formName = "thread-reply";

    return (
      <div className={`wib-thread-reply ${this.props.reply?.is_admin ? "is-edit" : ""}`}>
        {this.state.editing || this.props.creating ? (
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={this.handleSubmit}
          >
            {({ values, handleChange, setFieldValue, handleBlur, touched, errors, resetForm, dirty }) => (
              <Form className={`wf wf-form wf-${formName}`} name={formName}>
                <FormField
                  onBlur={handleBlur}
                  labelText="Comment Text"
                  formName={formName}
                  itemName="text"
                  value={values.text}
                  onChange={handleChange}
                  customInput={() => (
                    <TextareaAutosize
                      ref={this.textAreaRef as any}
                      onKeyDown={(e) => {
                        if (e.which === 9) {
                          if (this.textAreaRef.current !== null && this.textAreaRef.current !== undefined) {
                            insertTextAtCursor(this.textAreaRef.current, "    ");
                            e.preventDefault();
                          }
                        }
                      }}
                      onBlur={handleBlur}
                      value={values.text}
                      onChange={handleChange}
                      name="text"
                    />
                  )}
                />
                {errors.text && touched.text && <div className="wib-error">{errors.text}</div>}
                <div className="wib-actions-container">
                  {/* {dirty && (
                  )} */}
                  <button type="submit" className="submit-reply">
                    submit
                  </button>
                  <button
                    className="cancel-reply"
                    onClick={() => {
                      this.state.editing && this.setState({ editing: false });
                      this.props.creating && this.props.onCancelCreate && this.props.onCancelCreate();
                      resetForm();
                    }}
                  >
                    cancel
                    {/* {this.props.creating ? "cancel" : "reset"} */}
                  </button>
                  {/* {(this.props.creating || dirty) && (
                  )} */}
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <>
            <WIBEditable defaultValue={this.props.reply?.text || ""} />
          </>
        )}
        {this.props.reply !== null && this.props.reply !== undefined && (
          <Link to={`/profile/${this.props.reply!.user_id}`} className="wib-thread-username">
            {`@${this.props.reply!.username}`}
          </Link>
        )}
        {this.props.reply?.is_admin && (
          <div className="wib-actions-container">
            {" "}
            <EditIcon onClick={() => this.setState({ editing: !this.state.editing })} />{" "}
          </div>
        )}
        {/*         
        {this.props.reply !== undefined && (
          <Link
            to={`/profile/${this.props.reply!.user_id}`}
            className="wib-thread-username"
          >
            {`@${this.props.reply!.username}`}
          </Link>
        )}
        {(this.props.reply?.is_admin || this.props.creating) &&
          this.state.dirty && (
            <div className="wib-reply-actions">
              <button
                onClick={() =>
                  this.props.creating ? this.handlePost() : this.handleUpdate()
                }
                className="wib-reply-submit"
              >
                {this.props.creating ? "post" : "update"}
              </button>
              {!this.props.creating && (
                <button
                  onClick={() =>
                    this.setState({
                      dirty: false,
                      text: this.props.reply?.text || "",
                    })
                  }
                >
                  cancel
                </button>
              )}
            </div>
          )} */}
        {this.props.reply?.is_edited === 1 && <div className="reply-edited-message">edited</div>}
      </div>
    );
  }
}
