import React, { useState, useCallback } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import Cropper from "react-easy-crop";
import { Slider } from "@material-ui/core";
import { getOrientation } from "get-orientation/browser";
import { getCroppedImg, getRotatedImage } from "./canvasUtils";
import FileDropper from "../FileDropper";

const ORIENTATION_TO_ANGLE = {
  "3": 180,
  "6": 90,
  "8": -90,
};

const CropTool = ({ onCrop }) => {
  const [imageSrc, setImageSrc] = React.useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [error, setError] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, rotation);
      console.log("donee", { croppedImage });
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels, rotation]);

  const onClose = useCallback(() => {
    setCroppedImage(null);
  }, []);

  const onFileChange = async (files) => {
    if (files && files.length > 0) {
      const file = files[0];
      let imageDataUrl = await readFile(file);

      // apply rotation if needed
      const orientation = await getOrientation(file);
      const rotation = ORIENTATION_TO_ANGLE[orientation];
      if (rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
      }

      setImageSrc(imageDataUrl);
    }
  };

  return (
    <div>
      {imageSrc ? (
        <>
          <div className={"crop-container"} style={{ position: "relative", width: 400, height: 400 }}>
            <Cropper
              image={imageSrc}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              containerCasses={{ containerClassName: "crop-container-inner" }}
            />
          </div>
          <div className={"controls-container"} style={{ position: "relative", padding: 30 }}>
            <div className={"zoom-container"}>
              <div className="zoom-text">Zoom</div>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, zoom) => setZoom(zoom)}
              />
            </div>
            <div className={"rotation-container"}>
              <div className="rotation-text">Rotation</div>
              <Slider
                value={rotation}
                min={0}
                max={360}
                step={1}
                aria-labelledby="Rotation"
                onChange={(e, rotation) => setRotation(rotation)}
              />
            </div>
            <button
              onClick={async () => {
                try {
                  const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, rotation);
                  onCrop(croppedImage);
                } catch (err) {
                  setError("Could not crop. Please try with a smaller picture.");
                  setImageSrc(null);
                }
              }}
              className="wib-button"
            >
              Crop
            </button>
          </div>
        </>
      ) : (
        <>
          <FileDropper fileType="image/*" onDrop={onFileChange} />
          {error && <div className="wib-error">{error}</div>}
        </>
      )}
    </div>
  );
};

CropTool.propTypes = {
  onCrop: PropTypes.any,
};

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}
export default CropTool;
