import React from "react";
import AppState from "../../types/AppState";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { fetch_project } from "../../ducks/project";
import { match } from "react-router";
import { ThunkDispatch } from "redux-thunk";
import ProjectStub from "./ProjectStub";
import Collabs from "./Collabs";
import { flashMessage, flashErrorMessage } from "redux-flash";
import connector from "../../common_connector";
import Integrations from "./Integrations";
import Roles from "./Roles";
import UserContext from "../../UserContext";
import Tags from "../Tags";
import TagFormat from "../../types/TagFormat";
import Modules from "./Modules";
import { Tag } from "../../types/Tag";
import get_project_tags from "../../requests/get_project_tags";
import { ifError } from "assert";
import Threads from "../Threads";

interface Props {
  appState: AppState;
  dispatch: ThunkDispatch<AppState, any, any>;
  match: match<{ project_id: string }>;
  setEditor: (editor: boolean) => void;
}

interface State {
  tags: Tag[];
}

export class Project extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = { tags: props.appState.project.tags };
  }

  handleRefreshTags = async (pid?: number) => {
    let { project_id } = this.props.appState.project;
    if (pid !== null && pid !== undefined) {
      project_id = pid;
    }
    try {
      const tags = await get_project_tags({ project_id });
      this.setState({ tags });
    } catch (err) {
      this.props.dispatch(flashErrorMessage("error occured refreshing tags"));
      console.log(`error refreshing tags ${JSON.stringify(err)}`);
    }
  };

  componentDidMount() {
    const project_id = parseInt(this.props.match.params.project_id);
    if (isNaN(project_id)) {
      return;
    }
    this.props.dispatch(fetch_project(project_id));
    this.handleRefreshTags(project_id);
  }

  componentDidUpdate(props: Props) {
    if (
      props.appState.project.project_id !== this.props.appState.project.project_id ||
      props.appState.project.isAdmin !== this.props.appState.project.isAdmin
    )
      this.props.setEditor(this.props.appState.project.isAdmin);
  }

  canEdit = (verify: any) => () => {
    verify();
    return this.props.appState.project.isAdmin;
  };

  render() {
    console.log(this.props);
    return (
      <UserContext.Consumer>
        {({ verifyLoggedin }) => (
          <div className="wib-content wib-context-project">
            <>
              <ProjectStub canEdit={this.canEdit(verifyLoggedin)} />
              <Collabs canEdit={this.canEdit(verifyLoggedin)} />
              <Integrations canEdit={this.canEdit(verifyLoggedin)} />
              <Roles canEdit={this.canEdit(verifyLoggedin)} />
              <Modules onRefreshTags={this.handleRefreshTags} />
              <Threads project_id={parseInt(this.props.match.params.project_id)} />
              <Tags users={this.props.appState.project.users} tags={this.state.tags} format={TagFormat.clist} />
            </>
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

export default connector(Project);
