import React from "react";
import uniqid from "uniqid";

export default function STLView(props: { docName: string; docPath: string }) {
  return (
    <div className="pdf-iframe-wrapper">
      <iframe title={uniqid()} src={`https://origin.whatimbuilding.com/viewer/index.html?file=${props.docPath}`} />
      {/* {path.extname(props.docName).toLowerCase() === ".stl" && (
        <STLViewer url={props.docPath} width={628} />
      )}
      {path.extname(props.docName).toLowerCase() === ".obj" && (
        <OBJViewer url={props.docPath} />
      )} */}
    </div>
  );
}
