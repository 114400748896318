import React from "react";
import connector, { IProps } from "../common_connector";
import { set_loading } from "../ducks/loading";
import { fetch_projects } from "../ducks/projects";
import Spinner from "react-spinners/GridLoader";
import IProjectStub from "../types/ProjectStub";
import ProjectStub from "./ProjectStubRender";
import queryString from "querystring";
import fetch_projects_by_tags from "../requests/fetch_projects_by_tags";
import { fetch_tags } from "../ducks/tags";
import Tags from "./Tags";
import TagFormat from "../types/TagFormat";
import { PoorMansUnknown } from "qs";
import WibModal from "./Atoms/WibModal";
import Login from "./Login";
import { isObject } from "util";
import { Redirect } from "react-router";
import _ from "lodash";
import { Tag } from "../types/Tag";
import get_tags from "../requests/get_tags";

interface Props extends IProps {
  location: any;
}
interface State {
  loading: boolean;
  searchString: string;
  tags: Tag[];
  projects: IProjectStub[];
  redirectToSearch: boolean;
  selectedTags: string[];
}

class Search extends React.Component<Props, State> {
  state = {
    searchString: "",
    selectedTags: new Array<string>(),
    loading: false,
    projects: new Array<IProjectStub>(),
    redirectToSearch: false,
    tags: new Array<Tag>(),
  };

  refetch = async () => {
    const { search } = window.location;
    const searchParams = queryString.parse(search.replace("?", ""));
    let { selectedTags } = this.state;

    if (searchParams.tag) {
      console.log(searchParams.tag);
      selectedTags = (searchParams.tag as string).split(","); //[...this.state.tags, searchParams.tag as string];

      try {
        const res = await fetch_projects_by_tags({
          tag_list: selectedTags,
        });
        console.log(`RESPONSE: ${JSON.stringify(res)}`);

        this.setState({ projects: res.projects, selectedTags, tags: res.tags });
      } catch (err) {
        console.log(`ERROR: ${JSON.stringify(err)}`);
      }
      return;
    }

    try {
      const projects = await fetch_projects_by_tags({
        tag_list: selectedTags,
      });
      const tags = await get_tags(0);
      this.setState({ projects, tags });
    } catch (err) {
      console.log(`ERROR: ${JSON.stringify(err)}`);
    }
  };

  async componentDidMount() {
    this.props.dispatch(fetch_tags());
    this.refetch();
  }

  componentDidUpdate(newProps: Props) {
    //if (!_.isEqual(newProps.location, this.props.location)) this.refetch();
  }

  render() {
    const qs = this.state.selectedTags.length === 0 ? "" : `${this.state.selectedTags.join(",")},`;
    return (
      <>
        {this.state.redirectToSearch && (
          <Redirect to={`/?tag=${this.state.selectedTags.length === 0 ? "" : this.state.selectedTags.join(",")}`} />
        )}
        <Spinner loading={this.state.loading} />
        <div className="wib-content wib-search-landing wib-context-landing">
          <div className="home-search-container">
            <div className="wib-metafilter">
              {this.state.selectedTags.map((tag) => (
                <div className="metafilter-wrapper">
                  <div
                    className="metafilter-removebutton"
                    onClick={() =>
                      this.setState({
                        redirectToSearch: true,
                        selectedTags: this.state.selectedTags.filter((t) => t !== tag),
                      })
                    }
                  >
                    x
                  </div>
                  <div className="wib-metafilter-filter">#{tag}</div>
                </div>
              ))}
            </div>
            <div className="wib-search-title"></div>
            <div className="wib-searchbar">
              <input
                placeholder="search title or description"
                onChange={(e) => this.setState({ searchString: e.target.value })}
                className="wff-wrap wff-wrap-text wff-wrap-Step-step-title"
              />
            </div>
            <div className="hint"></div>
          </div>
          <div className="project-list-projects plp wib-collection">
            {this.state.projects
              .filter(
                (proj: IProjectStub) =>
                  proj.title.toLowerCase().includes(this.state.searchString.toLowerCase()) ||
                  proj.proj_desc.toLowerCase().includes(this.state.searchString.toLowerCase())
              )
              .map((project: IProjectStub) => (
                <ProjectStub key={project.id} project={project} />
              ))}
          </div>
          <Tags
            activeTags={this.state.selectedTags}
            tags={this.state.tags || []}
            format={TagFormat.clist}
            link={(tag) => `/search?tag=${qs}${tag}`}
          />
        </div>
      </>
    );
  }
}

export default connector(Search);
