import React, { Suspense, PropsWithChildren } from "react";

type Props = PropsWithChildren<{}>;

export default class LazyWrapper extends React.Component<Props, { hasError: boolean }> {
  state = { hasError: false };

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <p>Loading failed! Please reload.</p>;
    }
    return <Suspense fallback={<div className="loading-message">loading...</div>}>{this.props.children}</Suspense>;
  }
}
