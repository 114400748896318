import React, { ReactElement, useState } from "react";
import Profile from "../../types/Profile";
import Text from "../Atoms/Text";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import { USER_CONTENT_URL } from "../../requests/base_url";

interface Props {
  user: Profile;
  newProfilePhoto?: string;
  onChangeImage: (file: File) => void;
  onImageEditToggle: VoidFunction;
  canEdit: boolean;
}

export default function UserDetails({
  newProfilePhoto,
  user,
  onChangeImage,
  onImageEditToggle,
  canEdit,
}: Props): ReactElement {
  const profilePhoto = newProfilePhoto
    ? newProfilePhoto
    : user.profile_photo !== undefined
    ? `${USER_CONTENT_URL}/${user.id}/${user.profile_photo}`
    : undefined;
  return (
    <div className="profile-user-details">
      <div className="user-details-image-container">
        {profilePhoto !== undefined ? (
          <img src={profilePhoto} className="user-details-image-exists user-details-image" />
        ) : (
          <img className="user-details-image-notfound user-details-image"></img>
        )}
        {canEdit && (
          <button onClick={onImageEditToggle} className="change-profile-photo">
            change profile photo
          </button>
        )}
      </div>
      <div className="profile-user-details-position">{user.activity_current_title}</div>
      <div className="profile-user-details-description">{user.user_current_activity}</div>
      <div className="profile-user-details-lookingfor">{user.user_status}</div>
      <div className="profile-user-details-additional">{user.addtl_info}</div>
      {/* <div className="profile-user-details-colleagues">
        {user.collabs.map((collab) => (
          <div
            key={`${collab.username}-${collab.collaborator_id}`}
            className="profile-user-details-colleague"
          >
            <Text name="user-details-colleague-text">
              <Link to={`/profile/${collab.collaborator_id}`}>
                {`${collab.firstname} ${collab.lastname}`}
              </Link>
            </Text>
          </div>
        ))}
      </div> */}
    </div>
  );
}
