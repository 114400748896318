import React, { useState } from "react";
import MarkdownRenderer from "react-markdown";
import Editor from "react-simple-code-editor";
import highlight from "highlight.js";

interface Props {
  onContentChange: (content: string) => void;
  editor: boolean;
  defaultValue: string;
}

export default (props: Props) => {
  const [dirty, setDirty] = useState(false);
  const [text, setText] = useState(props.defaultValue);
  return (
    <div className="markdown-container">
      {props.editor && (
        <div className="markdown-input-container">
          <div className="codeinput">
            <Editor
              onBlur={() => {
                if (dirty) {
                  setDirty(false);
                  props.onContentChange(text);
                }
              }}
              onValueChange={(codeValue) => {
                setDirty(true);
                setText(codeValue);
              }}
              value={text}
              highlight={(code) => highlight.highlightAuto(code).value}
            />
          </div>
          <a href="https://guides.github.com/features/mastering-markdown/" target="_blank">
            Markdown Cheat Sheet
          </a>
        </div>
      )}
      <div className="markdown-rendered-text">
        <MarkdownRenderer source={text} />
      </div>
    </div>
  );
};
